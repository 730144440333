import React, {useState} from "react";
import {
    Box,
    ClickAwayListener,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    MenuList,
    Paper,
    Typography
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import {TaskList} from "../../model/TaskList";
import {Task} from "../../model/Task";

export interface Props {
    taskList: TaskList
    setTaskList: (t: TaskList) => void
}

export default function TaskListComp(props: Props) {
    const [selectedTask, setSelectedTask] = useState<Task>()

    function deleteTask(task: Task) {
        setSelectedTask(undefined)
        props.setTaskList(props.taskList?.remove(task))
    }

    return (
        <Box sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}>
            <nav aria-label="TaskList">
                <List>
                    {props.taskList?.tasks.map((t, i) => {
                        return (<ListItem disablePadding key={"task_" + i}>
                            <ListItemButton onClick={e => setSelectedTask(t)}>
                                <ListItemIcon>
                                    <TaskAltIcon/>
                                </ListItemIcon>
                                <ListItemText primary={t.name}/>
                            </ListItemButton>
                        </ListItem>)
                    })
                    }
                </List>
                {
                    selectedTask && <ClickAwayListener onClickAway={event => setSelectedTask(undefined)}>
                        <Paper sx={{width: 320, maxWidth: '100%'}}>
                            <MenuList>
                                <MenuItem onClick={event => deleteTask(selectedTask)}>
                                    <ListItemIcon>
                                        <DeleteIcon fontSize="small"/>
                                    </ListItemIcon>
                                    <ListItemText>Delete</ListItemText>
                                    <Typography variant="body2" color="text.secondary">
                                        ⌘D
                                    </Typography>
                                </MenuItem>
                            </MenuList>
                        </Paper>
                    </ClickAwayListener>
                }
            </nav>
        </Box>
    );
}
