import {Box, Button, FormControl, TextField} from "@mui/material";
import React, {useState} from "react";
import {TaskList} from "../../model/TaskList";
import TaskListComp from "../components/TaskListComp";
import {Task} from "../../model/Task";

export default function ListEditorView() {
    const [curList, setCurList] = useState(new TaskList("NewList", new Date()))
    const [taskName, setTaskName] = useState<string>("")


    const comparator = (task1: Task, task2: Task) => {
        const res = prompt(`Is '${task1.name}' more important than '${task2.name}'?`, 'yes')
        return res === 'yes' ? 1 : -1
    }

    function addTask() {
        const newTask = new Task(taskName, new Date())
        setCurList(curList.add(newTask, comparator))
    }

    return (
        <Box sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}>
            <nav aria-label="main editor">
                <FormControl>
                    <TextField id="task-name" label="Task Name" variant="filled"
                               onChange={event => setTaskName(event.target.value)}/>
                    <Button variant="contained" onClick={event => {
                        addTask()
                    }}
                            disabled={!taskName}
                    >Add Task</Button>
                </FormControl>
                <TaskListComp taskList={curList} setTaskList={setCurList}/>
            </nav>
        </Box>
    );
}
