import {Task} from './Task'

export class TaskList {
  /**
   * From lowest to highest priority.
   */
  constructor(
    public name: string,
    public createdAt: Date = new Date(),
    public lastModified: Date = new Date(),
    public description?: string,
    public tasks: Task[] = []
  ) {}

  public add(task: Task, comparator: PriorityComparator, startPosition = 0.5): TaskList {
    const tasks = this.subAdd(task, comparator, startPosition, this.tasks)
    return new TaskList(this.name, this.createdAt, new Date(), this.description, tasks)
  }

  private subAdd(
    task: Task,
    comparator: PriorityComparator,
    startPosition: number,
    taskSubList: Task[]
  ): Task[] {
    if (!taskSubList.length) {
      return [task]
    }

    const splitIndex = Math.floor(taskSubList.length * startPosition)

    const other: Task = taskSubList[splitIndex]
    const lower = taskSubList.slice(0, splitIndex)

    // as we floor the index, other is always in the upper array and we need to get rid of it here.
    const upper = taskSubList.slice(splitIndex + 1, taskSubList.length)

    const compRes = comparator(task, other)
    if (compRes === 0) {
      return [...lower, task, other, ...upper]
    } else if (compRes > 0) {
      return [...lower, other, ...this.subAdd(task, comparator, 0.5, upper)]
    } else if (compRes < 0) {
      return [...this.subAdd(task, comparator, 0.5, lower), other, ...upper]
    }

    throw new Error('Comparator returned an invalid value ' + compRes)
  }

  public remove(task: Task): TaskList {
    const tasks = this.tasks.filter((v) => v !== task)
    return new TaskList(this.name, this.createdAt, new Date(), this.description, tasks)
  }
}

export type PriorityComparator = (task1: Task, task2: Task) => number
